import axios from 'axios'
// a global url used in all of the requests
import baseURL from './baseUrl'
import type { LoginResponse } from '../features/user'
// import { store } from '../app/store';
// console.log('store', store.getState())

// decplaring axios as apiCleint
const { globalUrl: url } = baseURL()
// const getRefreshToken = (state:any) => state.user;
// const refreshToken = getRefreshToken(store.getState());
// console.log('refreshToken', refreshToken)
export const apiClient = axios.create({
    baseURL: `${url}api/`,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
})
export const apiClientForm = axios.create({
    baseURL: `${url}api/`,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    },
})
const requestQueue: Array<{
    resolve: (value: any) => void
    reject: (error: any) => void
    request: any
}> = []

// export const refreshAccessToken = async () => {
//     // Implement the logic to refresh the access token using the refresh token.
//     try {
//         const res = await dashBoardServices.refreshToken(
//             // @ts-expect-error accepted
//             localStorage.getItem('refreshToken')
//         )
//         console.log('res', res)
//         const access = (res.data as LoginResponse).access
//         const refresh = (res.data as LoginResponse).refresh
//         localStorage.setItem('refreshToken', refresh)
//         return access
//     } catch (e) {
//         console.log('e', e)
//     }
//     return ''
// }
let isRefreshing = false
const refreshPromise: any = null

export const refreshAccessToken = async () => {
    console.count('refreshAccessToken')
    console.log('isRefreshing', isRefreshing)
    if (!isRefreshing) {
        isRefreshing = true

        try {
            const refresh =
                localStorage.getItem('refreshToken') ??
                sessionStorage.getItem('refreshToken')
            const accessTokenStorage = localStorage.getItem('accessToken')
                ? 'localStorage'
                : 'sessionStorage'

            if (!refresh) {
                // Handle the case where refresh token is not found in either localStorage or sessionStorage
                throw new Error('Refresh token not found')
            }

            const response = await axios.post(
                `${url}api/v1/auth/token/refresh/`,
                {
                    refresh,
                }
            )

            console.log('response', response)
            const newAccessToken: string = response.data.access ?? ''

            // Update the stored access token in both localStorage and sessionStorage
            if (accessTokenStorage === 'localStorage') {
                localStorage.setItem('accessToken', newAccessToken)
                localStorage.setItem('refreshToken', response.data.refresh)
            } else {
                sessionStorage.setItem('accessToken', newAccessToken)
                sessionStorage.setItem('refreshToken', response.data.refresh)
            }
            isRefreshing = false
            requesting = false
            apiClient.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`
            return newAccessToken
        } catch (error) {
            // Handle token refresh error, e.g., logout the user
            // console.error('Token refresh failed:', error)
            // Optionally, clear local storage and redirect to the login page
            localStorage.clear()
            sessionStorage.clear()
            window.location.reload()
            throw error
        }
    } else {
        // If a refresh request is already in progress, return the promise
        return ''
    }
}

// Request interceptor
let requesting = false
apiClient.interceptors.request.use(
    async (config: any) => {
        console.log('requestinterceptor', requestQueue)
        config.headers.Authorization = `Bearer ${getToken() ?? ''}`
        if (isRefreshing || requesting) {
            // Create a promise that resolves when the token is refreshed
            const newRequest = new Promise((resolve, reject) => {
                requestQueue.push({ resolve, reject, request: config })
            })

            // Return the promise to pause this request until the token is refreshed
            return await newRequest
        } else {
            requesting = true
            return config
        }
    },
    (error) => Promise.reject(error)
)

// Response interceptor
apiClient.interceptors.response.use(
    async (response) => {
        requesting = false

        console.log('responsestatus', response)
        if (response.status !== 401) {
            requestQueue.forEach((request) => {
                console.log('requestforEach', request)
                request.request.headers.Authorization = `Bearer ${
                    getToken() ?? ''
                }`
                request.resolve(request.request)
            })
            requestQueue.length = 0
        }else{
            
            console.log('response', response)
        }
        return response
    },
    async (error) => {
        console.log('errorresponse', error)
        const originalRequest = error.config
        console.log('originalRequest', originalRequest._retry)
        requesting = false
       
        if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry &&
            !isRefreshing
        ) {
            originalRequest._retry = true

            // Refresh the token and retry the original request
            let newToken = ''
            try {
                const newAccessToken = (await refreshAccessToken()) ?? ''
                newToken = newAccessToken
                apiClient.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
                console.log('afterRefresh')
                return await apiClient(originalRequest)
            } catch (refreshError) {
                // Handle token refresh error here
                console.error('Token refresh error:')
                // Optionally, clear local storage and redirect to the login page
                // localStorage.clear();
                // window.location.href = '/login';
                window.localStorage.clear()
                // window.location.reload()
                return await Promise.reject(refreshError)
            } finally {
                // After the token is refreshed, resolve all waiting requests
                requestQueue.forEach((request) => {
                    console.log('requestforEach', request)
                    request.request.headers.Authorization = `Bearer ${newToken}`
                    request.resolve(request.request)
                })
                requestQueue.length = 0
            }
        }
        if (isRefreshing) {
            return await apiClient(originalRequest)
        }
        requestQueue.forEach((request) => {
            console.log('requestforEach', request)
            request.request.headers.Authorization = `Bearer ${getToken() ?? ''}`
            request.resolve(request.request)
        })
        requestQueue.length = 0
        if (
            error.response &&
            error.response.status === 401 &&
            originalRequest._retry 
           && error.response.data.code=="user_inactive"

        ) {
            localStorage.clear()
            sessionStorage.clear()
            window.location.reload()
        }
        return await Promise.reject(error)
    }
)

export const getToken = () => {
    const accessTokenStorage = localStorage.getItem('accessToken')
        ? 'localStorage'
        : 'sessionStorage'
    console.log('accessTokenStorage', accessTokenStorage)

    // Update the stored access token in both localStorage and sessionStorage
    if (accessTokenStorage === 'localStorage') {
        return localStorage.getItem('accessToken')
    } else {
        return sessionStorage.getItem('accessToken')
    }
}
// apiClient.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config
//         const { response } = error

//         if (response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true

//             if (!isRefreshing) {
//                 isRefreshing = true
//                 refreshPromise = refreshAccessToken()
//                     .then((newAccessToken: string) => {
//                         // Update the Authorization header with the new access token
//                         apiClient.defaults.headers.Authorization = `Bearer ${newAccessToken}`
//                         apiClientForm.defaults.headers.Authorization = `Bearer ${newAccessToken}`
//                         originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
//                         return apiClient(originalRequest)
//                     })
//                     .catch((refreshError) => {
//                         // Handle token refresh failure (e.g., logout the user)
//                         console.error('Token refresh failed:', refreshError)
//                         window.localStorage.clear()
//                         throw refreshError
//                     })
//                     .finally(() => {
//                         isRefreshing = false
//                         refreshPromise = null
//                     })
//             }

//             return refreshPromise
//         } else if (response.status === 401) {
//             if (
//                 !(
//                     originalRequest.url !== '/v1/auth/login/' &&
//                     originalRequest.url !== '/v1/auth/password/reset/'
//                 )
//             ) {
//                 window.localStorage.clear()
//                 window.location.reload()
//             }
//         }

//         // If the response status is not 401 or the request has already been retried, reject the error
//         return await Promise.reject(error)
//     }
// )

const dashBoardServices = {
    // TODO: fix the endpoint url
    getCustomersList(filterCustomerDashboard: {
        search: string
        contract_ends_by: string
        have_sla: string
        do_not_have_sla: string
        with_expired_contract: string
    }) {
        console.log('filterCustomerDashboard', filterCustomerDashboard)
        return apiClient.get(
            `/v1/service/customer/cards/list/?search=${filterCustomerDashboard.search}&contract_ends_by=${filterCustomerDashboard.contract_ends_by}&have_sla=${filterCustomerDashboard.have_sla}&do_not_have_sla=${filterCustomerDashboard.do_not_have_sla}&with_expired_contract=${filterCustomerDashboard.with_expired_contract}`
        )
    },
    getCustomersTableList(searchText: string, page: number) {
        return apiClient.get(
            `/v1/service/customer/list/?page=${page}&search=${searchText}`
        )
    },
    getExportedFileExcel() {
        return apiClient.get(
            `/v1/service/request/list/export-excel/`,{responseType: 'arraybuffer' }
        )
    },
    // create new customer service
    createNewCustomer(inputsObj: any) {
        return apiClient.postForm('/v1/repo/customer/create/', inputsObj)
    },
    // getting customer data service
    getCustomer(cusomterId: string) {
        return apiClient.get(`/v1/service/customer/${cusomterId}/`)
    },
    // update Customer Data service
    updateCustomer(cusomterId: string, inputsObj: any) {
        return apiClient.patchForm(
            `/v1/repo/customer/${cusomterId}/`,
            inputsObj
        )
    },
    //* * --------------- Customer Sites ---------------- * */
    // get customer sites.
    getCustomerSites(cusomterId: string) {
        return apiClient.get(`/v1/service/customer/${cusomterId}/`)
    },
    getCustomerForAdminUser(cusomterId: string) {
        return apiClient.get(
            `/v1/service/customer/${cusomterId}/for-admin-user/`
        )
    },
    getCustomerForPrjectManagerUser(cusomterId: string) {
        return apiClient.get(
            `/v1/service/customer/${cusomterId}/for-project-manager/`
        )
    },
    // create new customer site
    createCustomerSite(cusomterId: string, inputsObj: any) {
        return apiClient.post(
            `/v1/repo/customer/${cusomterId}/site/create/`,
            inputsObj
        )
    },
    // get all avaliabe timezones
    getTimeZones() {
        return apiClient.get('/v1/repo/customer/time_zone/')
    },
    getCustomerProducts(id: number) {
        if (id)
            return apiClient.get(`/v1/query/customer/products/?customer=${id}`)
        return apiClient.get(`/v1/query/customer/products/`)
    },
    getProductsQuery() {
        return apiClient.get(`/v1/query/customer/products/`)
    },
    getPriorities() {
        return apiClient.get(`/v1/query/customer/contract/priority/`)
    },
    getCustomerpriority(id: number) {
        if (id && id != 0)
            return apiClient.get(
                `/v1/query/customer/contract/priority/?customer=${id}`
            )
        return apiClient.get(`/v1/query/customer/contract/priority/`)
    },
    getCustomerPhoto(id: number) {
        return apiClient.get(`v1/repo/customer/${id}/for-normal-user/`)
    },
    // update Site data
    updateCustomerSite(
        cusomterId: string,
        siteId: string | number,
        inputsObj: any
    ) {
        return apiClient.patch(
            `/v1/repo/customer/${cusomterId}/site/${siteId}/`,
            inputsObj
        )
    },

    //* * --------------- Customer Contracts ---------------- * */
    getDefaultPriorities() {
        return apiClient.get(`/v1/repo/customer/contract/priority/`)
    },
    // create new customer contract
    createCustomerContract(cusomterId: string, inputsObj: any) {
        return apiClient.post(
            `/v1/service/customer/${cusomterId}/contract/create/`,
            inputsObj
        )
    },
    refreshToken(refreshToken: string) {
        return apiClient.post(`/v1/auth/token/refresh/`, {
            refresh: refreshToken,
        })
    },
    verifyToken(token: string) {
        return apiClient.post(`/v1/auth/token/verify/`, {
            token,
        })
    },
    // update customer contract
    updateCustomerContract(
        cusomterId: string,
        contractId: string | number,
        inputsObj: any
    ) {
        return apiClient.patch(
            `/v1/service/customer/${cusomterId}/contract/${contractId}/`,
            inputsObj
        )
    },
    updateRequest(reqId: string, inputsObj: any) {
        return apiClient.put(`/v1/service/request/${reqId}/change/`, inputsObj)
    },

    // create customer product data service
    createCustomerUser(cusomterId: number, body: any) {
        console.log('cusomterId', cusomterId)
        return apiClient.post(
            `/v1/service/customer/${cusomterId}/user/create/`,
            body
        )
    },
    updateCustomerUser(cusomterId: number, id: number, body: any) {
        return apiClient.put(
            `/v1/service/customer/${cusomterId}/user/${id}/`,
            body
        )
    },
    createCustomerProduct(cusomterId: number, body: any) {
        console.log('cusomterId', cusomterId)
        return apiClient.post(
            `/v1/service/customer/${cusomterId}/product/`,
            body
        )
    },
    updateCustomerProduct(cusomterId: number, body: any, productId: string) {
        console.log('cusomterId', cusomterId)
        return apiClient.put(
            `/v1/service/customer/${cusomterId}/product/${productId}/`,
            body
        )
    },

    getCustomerProduct(cusomterId: number, productId: number, body: any) {
        // console.log('heeeeeeeeeeeeeeeeeeeeeeeee', cusomterId)
        return apiClient.get(
            `/v1/service/customer/${cusomterId}/product/${productId}`,
            body
        )
    },
    getDashbaord(search: string) {
        return apiClient.get(`/v1/repo/Dashboard/${search}`)
    },

    login(inputsObj: any) {
        const api = axios.create({
            baseURL: `${url}api/`,
            withCredentials: false,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        return api.post('/v1/auth/login/', inputsObj)
    },

    reset(inputsObj: any) {
        const api = axios.create({
            baseURL: `${url}api/`,
            withCredentials: false,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        return api.post('/v1/auth/password/reset/', inputsObj)
    },
    resetForgetPassword(inputsObj: any, id: string, token: string) {
        const api = axios.create({
            baseURL: `${url}api/`,
            withCredentials: false,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        return api.post(`/v1/auth/resetpassword/${id}/${token}/`, inputsObj)
    },
    changePassword(inputsObj: any) {
        return apiClient.post('/v1/auth/password/change/', inputsObj)
    },

    createNewEngineer(inputsObj: any) {
        return apiClient.post('/v1/service/engineer/user/create/', inputsObj)
    },

    createRequestReply(id: number, inputsObj: any) {
        return apiClient.post(`/v1/repo/request/${id}/replay/`, inputsObj)
    },
    createNewRequest(inputsObj: any) {
        return apiClient.post('/v1/repo/request/', inputsObj)
    },
    escalateRequest(id: number, inputsObj: any) {
        return apiClient.put(`/v1/repo/request/escalate/${id}`, inputsObj)
    },
    newFileAttachment(inputsObj: any) {
        return apiClient.postForm('/v1/repo/request/attachment/', inputsObj)
    },
    newFileAttachmentToReplay(inputsObj: any) {
        return apiClient.postForm(
            '/v1/repo/request/replay/attachment/',
            inputsObj
        )
    },
    deleteFileAttachment(id: number) {
        return apiClient.delete(`/v1/repo/request/attachment/${id}/`)
    },
    updateEngineer(inputsObj: any, id: number) {
        return apiClient.put(
            `/v1/service/engineer/user/${id}/update/`,
            inputsObj
        )
    },
    updateEngineerOnline(inputsObj: any, id: number) {
        return apiClient.put(`/v1/repo/engineer/${id}/is_online/`, inputsObj)
    },
    deleteEngineer(id: number) {
        return apiClient.delete(`/v1/service/engineer/user/${id}/`)
    },
    deleteTeam(id: number) {
        return apiClient.delete(`/v1/repo/team/delete/${id}/`)
    },
    getEngineers() {
        return apiClient.get(`/v1/service/engineer/user/`)
    },
    getTeamEngineers() {
        return apiClient.get(`v1/service/team/engineers/`)
    },
    getTeams() {
        return apiClient.get(`/v1/repo/team/list/`)
    },
    getLeaderTeams() {
        return apiClient.get(`/v1/service/team/engineers/`)
    },
    getrequestsCustomer(searchString: string = '') {
        return apiClient.get(
            `/v1/service/request/list/customer/${searchString}`
        )
    },
    AddShift(engId: string, day: string, inputObject: any) {
        return apiClient.post(
            `/v1/service/engineer/${engId}/calendar/day/${day}/shift/`,
            inputObject
        )
    },
    markHoliday(engId: string, inputObject: any) {
        return apiClient.post(
            `/v1/service/engineer/${engId}/calendar/day/`,
            inputObject
        )
    },
    updateDaykHoliday(
        engId: string,
        inputObject: any,
        date: string,
        id: string
    ) {
        return apiClient.put(
            `/v1/service/engineer/${engId}/calendar/day/${date}/${id}/`,
            inputObject
        )
    },
    deleteShift(engId: string, day: string, shiftId: string) {
        return apiClient.delete(
            `/v1/service/engineer/${engId}/calendar/day/${day}/shift/${shiftId}/`
        )
    },
    editShift(engId: string, day: string, shiftId: string, inputObj: any) {
        return apiClient.put(
            `/v1/service/engineer/${engId}/calendar/day/${day}/shift/${shiftId}/`,
            inputObj
        )
    },
    getrequestsEngineer(searchString: string = '') {
        return apiClient.get(`/v1/service/request/list/${searchString}`)
    },
    getrequestsTeamLead(searchString: string = '') {
        return apiClient.get(`/v1/service/request/list/englead/${searchString}`)
    },
    getrequestById(id: number) {
        return apiClient.get(`/v1/service/request/${id}/`)
    },
    getCalenderById(id: number) {
        return apiClient.get(`/v1/service/engineer/${id}/calendar/day/`)
    },

    createTeam(inputsObj: any) {
        return apiClient.post(`/v1/repo/team/create/`, inputsObj)
    },
    updateTeam(inputsObj: any, id: number) {
        return apiClient.put(`/v1/repo/team/update/${id}/`, inputsObj)
    },
    createNewProduct(inputsObj: any) {
        return apiClient.post('/v1/service/product/', inputsObj)
    },
    createNewProductSkill(inputsObj: any) {
        return apiClient.post('/v1/repo/product-skill/', inputsObj)
    },
    deleteProductSkill(id: number) {
        return apiClient.delete(`/v1/repo/product-skill/${id}/`)
    },
    updateProductSkill(id: number, inputsObj: any) {
        return apiClient.put(`/v1/repo/product-skill/${id}/`, inputsObj)
    },
    updateProduct(productId: number, inputsObj: any) {
        return apiClient.put(`/v1/service/product/${productId}/`, inputsObj)
    },
    // get  products data service
    getProducts() {
        return apiClient.get(`/v1/service/product/`)
    },
    getProductsAdmin() {
        return apiClient.get(`/v1/service/customer_products/list/`)
    },

    // get customer product skills data service
    getCustomerProductSkills(prodId: string) {
        return apiClient.get(`/v1/service/product/${prodId}/`)
    },
    getCustomerIdProductIdSkills(customerId: string, prodId: string) {
        console.log('heeeeeeeeeeeeeeeeeeeeeeeeeeere')
        return apiClient.get(
            `/v1/service/customer/${customerId}/product/${prodId}/`
        )
    },

    // get  products data service
    getProductsContractPriority() {
        return apiClient.get(`/v1/repo/customer/contract/priority/`)
    },
}

export default dashBoardServices
